<template>
    <v-card
        :loading="loading"
        class="d-flex flex-column"
        hover
        width="270"
        height="380"
        ref="card"
        @click.stop="selected"
    >
        <div class="d-flex justify-center img-container">
            <img :src="getImageFile" class="promo-img" />
        </div>

        <v-card-title primary ref="title" class="title-container">
            <span :class="titleClass">
                {{ promo.Titolo }}
            </span>
        </v-card-title>
        <v-card-text>
            <v-icon left>
                mdi-calendar-start
            </v-icon>
            <span class="schedule-start">{{ scheduleText.start }} </span>
            <v-spacer></v-spacer>
            <v-icon left>
                mdi-calendar-end
            </v-icon>
            <span class="schedule-end">{{ scheduleText.end }} </span>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-chip-group class="mx-4 mb-2">
            <v-chip outlined :color="promoState(promo)">{{
                promoMessage(promo)
            }}</v-chip>
        </v-chip-group>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-if="!promo.Approvata"
                    class="alert-approved"
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-alert-circle</v-icon
                >
            </template>
            <span>Da Approvare</span>
        </v-tooltip>
    </v-card>
</template>

<script>
import { promoStateMessage } from "../../mixins/utils";

export default {
    props: {
        promo: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        loading: false,
        width: 0
    }),

    mixins: [promoStateMessage],

    computed: {
        filePath() {
            return "/files/fileidstream";
        },

        // promoState() {

        //     const disabled = compareDate(this.promo.FineValidita) === 1;
        //     const toStart = compareDate(this.promo.InizioValidita) === -1;
        //     const deleted = this.promo.Action === 1;
        //     const suspended = this.promo.Sospeso === true;

        //     if (disabled && !deleted || suspended) {
        //         return "warning";
        //     }
        //     else if (deleted) {
        //         return "error";
        //     } else  if (toStart) {
        //         return "info";
        //     }
        //     return "success";
        // },

        // promoMessage() {
        //     const disabled = compareDate(this.promo.FineValidita) === 1;
        //     const toStart = compareDate(this.promo.InizioValidita) === -1;
        //     const deleted = this.promo.Action === 1;
        //     const suspended = this.promo.Sospeso === true;

        //     //console.log(new Date(this.promo.InizioValidita).toLocaleDateString('it-IT'), dt.toLocaleDateString('it-IT'))

        //     if (suspended) {
        //         return "Sospesa";
        //     }
        //     else if (deleted) {
        //         return "Cancellata";
        //     } else if (disabled) {
        //         return "Scaduta";
        //     } else if (toStart) {
        //         return "Non Pubblicata";
        //     }
        //     return "Pubblicata";
        // },

        scheduleText() {
            // const from = new Date(this.promo.InizioValidita);
            // const to = new Date(this.promo.FineValidita);
            const from = this.promo.InizioPubblicazione === this.promo.InizioValidita ? new Date(this.promo.InizioValidita) : new Date(this.promo.InizioPubblicazione);
            const to = this.promo.FinePubblicazione === this.promo.FineValidita ? new Date(this.promo.FineValidita) : new Date(this.promo.FinePubblicazione);
            const validità = {
                start: from.toLocaleString("it-it", {
                    weekday: "short",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                }),
                end: to.toLocaleString("it-it", {
                    weekday: "short",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                })
            };

            return validità;

            // return `Da ${from.toLocaleString("it-it", {
            //     weekday: "long",
            //     year: "numeric",
            //     month: "long",
            //     day: "numeric"
            // })} a ${to.toLocaleString("it-it", {
            //     weekday: "long",
            //     year: "numeric",
            //     month: "long",
            //     day: "numeric"
            // })}`;
        },

        getImageFile() {
            return this.filePath + "/icoop_image/" + this.promo.ObjectIdImage;
        },

        titleClass() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "font-weight-bold text-subtitle-2";
                case "sm":
                    return "font-weight-bold text-subtitle-2";
                case "md":
                    return "font-weight-bold text-subtitle-2";
                case "lg":
                    return "font-weight-bold text-subtitle-1";
                case "xl":
                    return "font-weight-bold text-subtitle-1";
            }
            return "";
        },
        subtitleClass() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "font-weight-bold text-caption";
                case "sm":
                    return "font-weight-bold text-caption";
                case "md":
                    return "font-weight-bold text-caption";
                case "lg":
                    return "font-weight-bold text-caption";
                case "xl":
                    return "font-weight-bold text-caption";
            }
            return "";
        }
    },

    methods: {
        selected() {
            this.$emit("selected");
        }
    },
    mounted() {
        this.$nextTick(function() {
            setTimeout(() => {
                this.width = this.$refs.card.$el.clientWidth - 32;
            }, 500);
            //
            //
            // console.log(this.promo)
        });
    }
};
</script>

<style scoped>
.v-card__title {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.v-card__title > span {
    word-break: break-word;
}

.title-container {
    height: 96px;
}

.img-container {
    position: relative;
    height: 45%;
    /* height: 175px !important; */
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.promo-img {
    position: absolute;
    /* width: 100%;
    height: auto !important; */
    max-height: 100%;
    width: auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.schedule-start,
.schedule-end {
    text-transform: capitalize;
}

.alert-approved {
    position: absolute;
    top: 0;
    right: 8px;
    margin: 8px 0 0 0;
}
</style>
